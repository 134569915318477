<template>
  <section class="l-content">
    <el-card>
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业">
          <l-select v-model="condition.enterpriseCode" :remote="$api.enterpriseList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="记录状态">
          <l-select v-model="condition.borrowState" :data="$constant.borrowStateList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="使用时间">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产名称/使用人姓名"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="enterpriseName" slot-scope="scope">
          <el-button type="text" @click="$site.view_enterprise_detail(scope.row.enterpriseCode)">{{ scope.row.enterpriseName }}</el-button>
        </template>
        <template slot="assetInfo" slot-scope="scope">
          <el-button type="text" @click="$site.view_asset_detail(scope.row.assetId)">{{ scope.row.assetInfo.assetName }}</el-button>
        </template>
        <template slot="borrowState" slot-scope="scope">
          <span v-if="scope.row.borrowState == 0" class="text-blue">申请中</span>
          <span v-else-if="scope.row.borrowState == 1" class="text-orange">已借出</span>
          <span v-else-if="scope.row.borrowState == 8" class="text-brown">申请归还</span>
          <span v-else-if="scope.row.borrowState == 9" class="text-red">拒绝借出</span>
          <span v-else-if="scope.row.borrowState == 10" class="text-green">已归还</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'BorrowList',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '所属企业', slot: 'enterpriseName' },
          { label: '资产名称', slot: 'assetInfo' },
          { label: '责任人', prop: 'reponsibleUserName' },
          { label: '使用人', prop: 'borrowUserName' },
          { label: '使用时间', prop: 'borrowTime', filter: (a, data) => this.$lgh.limitTo(a, 16) },
          { label: '归还时间', prop: 'confirmReturnTime', filter: (a, data) => this.$lgh.limitTo(a, 16) },
          { label: '使用目的', prop: 'purpose' },
          { label: '状态', slot: 'borrowState' }
        ]
      },
      condition: {
        keyword: '',
        enterpriseCode: '',
        startDate: '',
        endDate: '',
        borrowState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/assetborrow/list', this.condition)
    }
  }
}
</script>
